<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="formData"
            :inputComponents="inputComponents"
          >
            <template v-slot:tab1TableDown>
              <div style="margin-top: 10px">
                <BaseLabel :columnInfo="detailInfo.sub_title2" />
                <div>
                  <b-form-row>
                    <b-col>
                      <BaseButton
                        class="edit-button"
                        variant="success"
                        :columnInfo="detailCtrlInfo.vehicle_add"
                        icon="plus-circle"
                        @click="vehicleAdd(obj)"
                      />
                      <BaseButton
                        class="edit-button"
                        variant="success"
                        :disabled="!isSelectedRequest"
                        :columnInfo="detailCtrlInfo.vehicle_delete"
                        icon="minus-circle"
                        @click="vehicleRemoveRow(obj)"
                      />
                    </b-col>
                    <b-col>
                      <BaseSelect
                        class="detail-input"
                        v-model="formData['vehiclePattern']"
                        :columnInfo="detailInfo.vehicle_pattern"
                        :options="comboItem.options"
                        :error-visible="true"
                        :rules="comboItem.rules"
                        :state="false"
                      />
                    </b-col>
                    <b-col>
                      <BaseButton
                        class="edit-button"
                        variant="success"
                        :columnInfo="detailCtrlInfo.vehicle_pattern_add"
                        icon="plus-circle"
                        @click="vehiclePatternAddRow(obj)"
                      />
                    </b-col>
                  </b-form-row>
                </div>
                <div class="vehicle-tab-pane">
                  <!-- テーブル -->
                  <BaseTable
                    select-mode="range"
                    ref="table"
                    :fields="fields"
                    :items="tableItems"
                    selectMode="single"
                    @row-selected="onRowSelectedVehicle"
                    @row-clicked="onRowClickedVehicle"
                  />
                </div>
                <!-- <BaseLabel :columnInfo="detailInfo.sub_title3" /> -->
                <!-- <BaseCheckboxGroup
                  class="detail-input"
                  v-model="formData['planning_group_ids']"
                  :columnInfo="detailInfo.planning_group_ids"
                  :options="checkBoxGroupItem.options"
                  :error-visible="true"
                  heightStyle=""
                  :rules="checkBoxGroupItem.rules"
                  :state="false"
                /> -->
              </div>
            </template>
          </BaseTabGroup>
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 開始 -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.start"
          @click="goStart"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 輸送手段マスタモーダル -->
    <VehicleModal
      id="vehicle-modal"
      type="entry"
      selectMode="single"
      :useUnset="false"
      @after-close-set="afterCloseSetVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 開始モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
    />
    <!-- @confirm="register(obj)" -->
  </div>
</template>

<script>
import PlanningRequestInfo from './PlanningRequestInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import {
  init,
  start,
  vehicleAdd,
  vehicleRemoveRow,
  vehiclePatternAddRow,
  addVehicleInfo,
} from '@/plan/autoPlanning/AutoPlanningRequestModal.js'
import { SCREEN_ID, SLOT_NAME } from '@/common/const.js'

const screenId = SCREEN_ID.AUTO_PLANNING_REQUEST_MODAL

export default {
  name: 'autoPlanningRequestModal',

  components: {
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
  },

  data() {
    return {
      formData: new PlanningRequestInfo(),
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItems: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      obj: this,

      registerMessage: null,

      detailRowIndex: -1,
      screenKbn: -1,
      requestId: -1,
    }
  },

  props: {
    selectedSearchCondition: {
      type: Object,
      default: () => {},
    },
    displayModals: {
      type: String,
      default: 'one',
    },
  },

  watch: {
    selectedSearchCondition() {
      this.screenKbn = this.selectedSearchCondition.screenKbn
      this.requestId = this.selectedSearchCondition.requestId
      init(this)
    },
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
      return info
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    inputComponents() {
      return [
        {
          // 自動配車指示（自動配車要求）タブ
          label: this.detailInfo.tab1.label,
          description: this.detailInfo.sub_title1.label,
          slot: SLOT_NAME.TAB1_TABLE_DOWN,
          inputComponents: [
            {
              // 出荷日
              type: 'dateFromTo',
              fromId: 'conditionBeginDate',
              toId: 'conditionEndDate',
              fromColumnInfo: this.detailInfo.condition_begin_date,
              toColumnInfo: this.detailInfo.condition_end_date,
              locale: this.locale,
              fromRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATERANGE,
              ],
              toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 目的関数(配車評価パターンID)
              type: 'select',
              id: 'planningEvaluationPatternId',
              columnInfo: this.detailInfo.planning_evaluation_pattern_id,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(
                this.MASTER_CODE.PLANNING_EVALUATION_PATTERN
              ),
              onChangeCallback: () => {},
            },
            {
              // 計画パターン(配車計画パターンID)
              type: 'select',
              id: 'planningPatternId',
              columnInfo: this.detailInfo.planning_pattern_id,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.PLANNING_PATTERN),
              onChangeCallback: () => {},
            },
            {
              // 最大計画回数
              type: 'text',
              id: 'maxPlanCount',
              columnInfo: this.detailInfo.max_plan_count,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
              ],
            },
            {
              // 最大計画時間
              type: 'text',
              id: 'maxPlanMin',
              columnInfo: this.detailInfo.max_plan_min,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
              ],
            },
          ],
        },
      ]
    },

    comboItem() {
      return {
        // 車両パターン
        type: 'select',
        id: 'vehiclePattern',
        columnInfo: this.detailInfo.vehicle_pattern,
        options: getListOptions(this.MASTER_CODE.VEHICLE_GROUP, false),
        onChangeCallback: () => {},
      }
    },
    // checkBoxGroupItem() {
    //   return {
    //     // 配車計画グループ
    //     type: 'checkboxgroup',
    //     id: 'planningGroupIds',
    //     columnInfo: this.detailInfo.planning_group_ids,
    //     //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
    //     options: getListOptions(this.MASTER_CODE.PLANNING_GROUP, false),
    //   }
    // },

    isSelectedRequest() {
      return this.selectedItems.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    init,
    start,
    vehicleAdd,
    vehicleRemoveRow,
    vehiclePatternAddRow,
    addVehicleInfo,

    goStart() {
      start(this)
    },

    onRowSelectedVehicle(selectedItems) {
      this.selectedItems = selectedItems
    },

    onRowClickedVehicle(item, index) {
      console.log('item.vehicle_id===>' + item.vehicle_id)
      console.log('item.index===>' + index)
      this.detailRowIndex = index
    },

    /**
     * 輸送手段マスタから取得データ設定
     */
    afterCloseSetVehicle(selectedItem) {
      addVehicleInfo(this, selectedItem)
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
}
// カレンダー表示切れないように一覧高さの最小値を設定
.vehicle-tab-pane {
  min-height: 80px;
}

/** 車両パターンコンボ幅 */
/* <select id="vehicle_pattern" name="vehicle_pattern" 
class="custom-select custom-select-sm" data-class="base-select"> 
.custom-select { width: 100%; }
*/
// span.detail-input {
//   width: 100px;
// }
// select.custom-select {
//   width: auto;
// }
</style>
