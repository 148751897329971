/**
 * 自動配車指示
 */
export default class PlanningRequestInfo {
  //ブロックコード
  blockCode
  //出荷日
  conditionBeginDate = null
  //出荷日To
  conditionEndDate = null
  //目的関数
  planningEvaluationPatternId = null
  //計画パターン
  planningPatternId = null
  //最大計画回数
  maxPlanCount
  //最大計画時間
  maxPlanMin
  //車両パターン
  vehiclePattern = null
  //車両IDリスト
  vehicleIds = []
  //配車計画グループIDリスト
  planningGroupIds = []
  //-------------------------------------
  //車両指定一覧
  vehicleDtos = []
}
