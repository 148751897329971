import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  convertSnakeCace,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import AutoPlanningService from './AutoPlanningService'
import _ from 'lodash'

export function init(obj) {
  // 初期コンボ情報取得処理
  //console.log('requestId===>' + obj.requestId)
  //console.log('screenKbn===>' + obj.screenKbn)

  // 項目初期化
  clear(obj)

  if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
    //console.log('[COPY]obj.requestId===>' + obj.requestId)
    // COPYの場合、
    obj.$store.dispatch('init/setServiceLoading')
    AutoPlanningService.get(obj.requestId)
      .then(
        (response) => {
          obj.formData = response.data
          // 出荷日
          obj.formData.conditionBeginDate = response.data.planBeginDate
          obj.formData.conditionEndDate = response.data.planEndDate
          // 目的関数(配車評価パターンID)
          obj.formData.planningEvaluationPatternId =
            response.data.planningEvaluationPatternId
          // 計画パターン(配車計画パターンID)
          obj.formData.planningPatternId = response.data.planningPatternId
          // 最大計画回数
          obj.formData.maxPlanCount = response.data.systemMaxPlanCount
          // 最大計画時間
          obj.formData.maxPlanMin = response.data.systemMaxPlanMin
          // 車両指定
          var datas = convertSnakeCace(response.data.vehicleConditionDtos)
          obj.tableItems = datas

          //オーダー配車計画グループ(チェックボックスグループ)
          let ids = response.data.orderConditionDtos.map(
            (item) => item.planningGroupId + ''
          )
          //obj.formData.planningGroupIds = ids
          obj.formData.planning_group_ids = ids
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

/**
 * 初期表示
 * @param {*} obj
 */
function clear(obj) {
  //ブロックコード
  obj.formData.blockCode
  //出荷日
  obj.formData.conditionBeginDate = null
  //出荷日To
  obj.formData.conditionEndDate = null
  //目的関数
  obj.formData.planningEvaluationPatternId = null
  //計画パターン
  obj.formData.planningPatternId = null
  //最大計画回数
  obj.formData.maxPlanCount = null
  //最大計画時間
  obj.formData.maxPlanMin = null
  //車両パターン
  obj.formData.vehiclePattern = null
  //車両IDリスト
  obj.formData.vehicleIds = []
  //配車計画グループIDリスト
  obj.formData.planningGroupIds = []
  obj.formData.planning_group_ids = []
  //-------------------------------------
  //車両指定一覧
  obj.formData.vehicleDtos = []
  obj.tableItems = []
}

/**
 * 「開始」押下処理
 * @param {*} obj
 */
export async function start(obj) {
  obj.$bvModal.hide('register-modal')
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return
  console.log('validated=' + validated)
  // // 車両指定一覧０件の場合、
  // if (obj.tableItems.length <= 0) {
  //   // %sを入力してください。
  //   openMsgModal(
  //     obj,
  //     MSG_TYPE.E,
  //     obj.$store.getters['init/getMessage'](
  //       'YZ00MG984E',
  //       obj.detailInfo.sub_title2.label
  //     )
  //   )
  //   return
  // }

  let newForm = _.cloneDeep(obj.formData)
  // サーバーパラメータPlanningStartInParamDto作成
  newForm.conditionBeginDate = formatYYYYMMDD(obj, newForm.conditionBeginDate)
  newForm.conditionEndDate = formatYYYYMMDD(obj, newForm.conditionEndDate)

  // チェックボックスグループ値を文字列から数字で変換
  let selected = newForm.planning_group_ids.map((data) => Number(data))
  newForm.planningGroupIds = selected

  // 車両IDリスト作成
  let vehicleIds = []
  if (obj.tableItems.length > 0) {
    vehicleIds = obj.tableItems.map((item) => item.vehicle_id)
  }
  newForm.vehicleIds = vehicleIds

  let startInParam = {
    planBeginDate: newForm.conditionBeginDate,
    planEndDate: newForm.conditionEndDate,
    planningEvaluationPatternId: newForm.planningEvaluationPatternId,
    planningPatternId: newForm.planningPatternId,
    maxPlanCount: newForm.maxPlanCount,
    maxPlanMin: newForm.maxPlanMin,
    vehicleIds: newForm.vehicleIds,
    planningGroupIds: newForm.planningGroupIds,
  }

  obj.$store.dispatch('init/setServiceLoading')
  AutoPlanningService.start(startInParam)
    .then(
      (response) => {
        let outDto = response.data
        obj.requestId = outDto.requestId
        console.log('requestId=>' + obj.requestId)

        if (obj.requestId >= 0) {
          // 自画面閉じる
          if (obj.displayModals != 'two') {
            obj.$bvModal.hide('auto-planning-request-modal')
          } else {
            obj.$bvModal.hide('auto-planning-request-modal-edit')
          }
          obj.$emit('after-close-set', obj.requestId)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 「追加」ボタン押下
 * @param {*} obj
 */
export function vehicleAdd(obj) {
  // 輸送手段参照開く
  openSubModal(obj, 'vehicle-modal')
}

/**
 * 「追加」ボタン押下時、輸送手段参照情報を車両指定一覧へ追加する
 * @param {*} obj
 * @param {*} vehicleItem
 */
export function addVehicleInfo(obj, vehicleItem) {
  if (vehicleItem) {
    let index = obj.tableItems.findIndex(
      (item) => item.vehicle_id == vehicleItem.vehicle_id
    )
    // 存在しないと、車両情報行を追加する
    if (index == -1) {
      let addInfo = makeTableRow(vehicleItem)
      obj.tableItems.push(addInfo)
    }
  }
  obj.formData.vehicleDtos = obj.tableItems
}

function makeTableRow(vehicleItem) {
  let addInfo = {
    vehicle_id: vehicleItem.vehicle_id,
    vehicle_code: vehicleItem.vehicle_code,
    vehicle_name: vehicleItem.vehicle_name, // 輸送手段名
    numberplate: vehicleItem.numberplate,
    vehicle_kind: vehicleItem.vehicle_kind, // 輸送手段区分（車種）
    vehicle_kind_name: vehicleItem.vehicle_kind_name,
    vehicle_model: vehicleItem.vehicle_model, // 車型
    vehicle_model_name: vehicleItem.vehicle_model_name,
    carrier_id: vehicleItem.carrier_id, // 運送会社
    carrier_code: vehicleItem.carrier_code,
    carrier_name: vehicleItem.carrier_name,
  }
  return addInfo
}

/**
 * 「削除」ボタン押下処理
 * @param {*} obj
 */
export function vehicleRemoveRow(obj) {
  console.log('requestRemoveRow==============' + obj)
  for (let info of obj.selectedItems) {
    const index = obj.tableItems.indexOf(info)
    console.log('selectedItems(index)' + index)
    obj.tableItems.splice(index, 1)
  }
  obj.formData.vehicleDtos = obj.tableItems
}

/**
 * 「車両パターン追加」ボタン押下処理
 * @param {*} obj
 */
export function vehiclePatternAddRow(obj) {
  //console.log('vehiclePatternAddRow==============' + obj)
  //console.log('vehiclePattern=' + obj.formData.vehiclePattern)

  if (obj.formData.vehiclePattern) {
    // 輸送手段グループ情報取得
    obj.$store.dispatch('init/setServiceLoading')
    AutoPlanningService.getVehicleGroup(obj.formData.vehiclePattern)
      .then(
        (response) => {
          //VehicleGroupDto
          let vehicles = response.data.vehicles
          if (vehicles.length > 0) {
            vehicles.forEach((dto) => {
              let index = obj.tableItems.findIndex(
                (item) => item.vehicle_id == dto.vehicleId
              )
              // 存在しないと、車両情報行を追加する
              if (index == -1) {
                let addInfo = {
                  vehicle_id: dto.vehicleId,
                  vehicle_code: dto.vehicleCode,
                  vehicle_name: dto.vehicleName, // 輸送手段名
                  numberplate: dto.numberplate,
                  vehicle_kind: dto.vehicleKind, // 輸送手段区分（車種）
                  vehicle_kind_name: dto.vehicleKindName,
                  vehicle_model: dto.vehicleModel, // 車型
                  vehicle_model_name: dto.vehicleModelName,
                  carrier_id: dto.carrierId, // 運送会社
                  carrier_code: dto.carrierCode,
                  carrier_name: dto.carrierName,
                }
                obj.tableItems.push(addInfo)
              }
            })
          }
          obj.formData.vehicleDtos = obj.tableItems

          if (obj.tableItems.length < 1) {
            // 警告モーダル表示
            openMsgModal(
              obj,
              MSG_TYPE.W,
              obj.$store.getters['init/getMessage']('YZ00MG907I')
            )
          }
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}
